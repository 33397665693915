.catalogue-container {
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;

    .card {
      flex: 0 0 calc(50% - .5rem);
      border-radius: 1rem;
      border: 1px solid #ddd;
      box-sizing: border-box;
      overflow: hidden;

      .image {
        position: relative;

        img {
          width: 100%;
        }
      }

      .detail {
        padding: .4rem .8rem;

        .title {
          font-size: 1rem;
          font-weight: 500;
          overflow: hidden;
          word-break: keep-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .point {
          font-size: .8rem;
          font-weight: 900;
          margin-top: .5rem;
        }
      }
    }
  }
}

.catalogue-detail-container {

  .image {
    width: 100%;
  }

  .detail {
    padding: 1rem;

    .label {
      font-size: 1rem;
      font-weight: 600;
    }

    .date,
    .note {
      font-size: .8rem;
      color: #7d7d7d;
      margin-top: .2rem;
    }

    .space {
      height: 1rem;
    }
  }

  .bottom-action {
    position: fixed;
    bottom: 0;
    max-width: 28rem;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
  }

  .bottom-sheet-content {
    padding: 1rem;

    .title {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .point {
      margin-top: .2rem;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;
      border-top: 1px solid #ddd;
      padding-top: .5rem;

      .my-point {
        flex: 0 0 50%;

        .point {
          font-weight: 600;
        }
      }
    }
  }
}
.loadmore-container {
  z-index: 1000000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1rem;

  .loader {
    height: 2rem;
  }
}
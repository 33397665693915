.bottle-container {
  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    .category {
      display: flex;
      gap: 0.2rem;
      overflow: auto;
      margin-bottom: 1rem;
      align-items: center;
      justify-content: center;

      > div {
        background-color: white;
        padding: 0.4rem 1rem;
        border-radius: 2rem;
        border: 1px solid #ddd;
        // min-width: 4rem;
        white-space: nowrap;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;

        &.active {
          background-color: black;
          color: white;
        }
      }
    }

    .card {
      background-color: #ffffff;
      border-radius: 1.5rem;
      border: 1px solid #eee;
      box-sizing: border-box;
      overflow: hidden;

      .detail {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-wrap {
          display: flex;
          flex-direction: column;
          align-items: left;
         
          .expired-title {
            color: red;
            font-size: 10px;
            margin-left: 5px;
            display: flex;
            align-items: center;
          }
          .picked-title {
            color: rgb(44, 230, 81);
            font-size: 10px;
            margin-left: 5px;
            display: flex;
            align-items: center;
          }
          .keeping-date {
            font-size: 10px;
          }
        }

        .date {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-top: 0.5rem;

          .icon {
            height: 1rem;
            opacity: 0.6;
          }

          span {
            font-size: 0.8rem;
            font-weight: 500;
            color: #7d7d7d;
          }
        }
      }
    }
  }
}

.bottle-detail-container {
  .content {
    .image {
      display: flex;
      background-color: #eee;
      padding: 10px;
      align-items: center;
      justify-content: center;
    }

    .detail {
      padding: 1rem;

      .label {
        font-size: 15px;
      }

      .title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .not-available-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .photo {
        display: grid;
        grid-template-columns: 2;
        gap: 3;
        margin: 10px 0;
        width: 10%;
      }

      .date {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 10px;

        .icon {
          height: 1rem;
          opacity: 0.6;
        }

        span {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
}

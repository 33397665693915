.notification-container {
  .content {
    display: flex;
    flex-direction: column;

    .card {
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      padding: .5rem 0;
      border-bottom: 1px solid #ddd;

      .top {
        display: flex;
        justify-content: space-between;
        padding: .4rem 1rem .2rem 1rem;

        .title {
          flex: 1;
          font-size: 1rem;
          font-weight: 600;
        }

        .date {
          font-size: .8rem;
          font-weight: 500;
          margin-top: .2rem;
        }

        .icon {
          height: 1rem;
          filter: brightness(0) invert(1);
        }
      }

      .detail {
        display: flex;
        align-items: center;
        gap: .5rem;
        flex: 2;
        box-sizing: border-box;
        padding: .5rem .8rem;

        .icon {
          height: 1rem;
          opacity: .7;
        }

        .message-content {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .body {
            flex-grow: 1;
            font-size: .8rem;
            font-weight: 500;
          }

          .thumbnail {
            height: 2rem;
            border-radius: .4rem;
          }
        }
      }
    }
  }
}

.notification-detail-container {
  .image {
    width: 100%;
  }

  .content {
    padding: 1rem;

    .date {
      display: flex;
      align-items: center;
      gap: .5rem;
      margin-top: .5rem;

      .icon {
        height: 1rem;
        opacity: .6;
      }

      span {
        font-size: .8rem;
        font-weight: 500;
        color: #7d7d7d;
      }
    }

    .body {
      margin-top: 1rem;
      font-size: 1rem;
    }
  }
}
.splash-container {
  position: relative;
  height: 100vh;
  height: 100dvh;
  background-color: black;

  .logo-client {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    opacity: 0;
    animation: show-logo-client 1s .5s ease forwards;
  }

  .powered-by {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    bottom: 1rem;
    opacity: 0;
    width: 100%;
    animation: show-powered-by .5s 1.5s linear forwards;

    .label {
      font-size: .8rem;
    }

    .logo-loyalid {
      height: 1.8rem;
    }
  }
}

@keyframes show-logo-client {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.1);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1);
  }
}

@keyframes show-powered-by {
  from {
    opacity: 0;
    transform: scale(1.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  z-index: 100000000000;

  .message-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    min-width: 15rem;
  }

  .alert-title {
    font-size: 1rem;
    font-weight: 600;
  }

  .alert-message {
    font-size: .8rem;
    margin-top: .5rem;
  }

  .button-alert {
    margin-top: 1rem;
    border: 1px solid #ddd;
    border-radius: 3rem;
    padding: .5rem;
    text-align: center;
    font-weight: 600;
  }
}
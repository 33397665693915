.login-container {
  padding: 1.5rem;
  background-color: black;
  height: 100%;

  .content {
    margin-top: 2rem;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #EAE9E8;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
    color: #EAE9E8;
  }

  .error-message {
    color: red;
    font-size: 12px;
  }
}

.input-wrapper {
  position: relative;

  input {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    border-radius: .75rem;
    border: 1px solid #eee;
    outline: 1px solid transparent;
    background-color: #f9f9f9;
    padding: .8rem;
    margin: .5rem 0;
    font-size: .9rem;
    transition: all .3s ease;

    &:hover {
      border-color: #eee;
    }

    &:focus,
    &:focus-visible,
    &:active {
      border: 1px solid #999;
      outline: 3px solid #6d91df1a;
    }
  }

  .eye {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      display: block;
      height: 1.2rem;
      opacity: .5;
    }
  }
}
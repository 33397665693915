.point-lucky-number-container {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: .2rem;
    background-color: black;
    border-bottom-left-radius: 10% 30%;
    border-bottom-right-radius: 10% 30%;
    margin-bottom: -4rem;
    padding: 2rem;
    padding-bottom: 4rem;
    text-align: center;

    .redeem-point {
      font-size: 2rem;
      font-weight: 600;
      color: white;
    }

    .label {
      font-size: 1rem;
      font-weight: 400;
      color: white;
    }
  }

  .content {
    padding: 1rem;

    .luckydraw-card {
      position: relative;
      background-color: #ffffff;
      border-radius: 1rem;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .2);
      box-sizing: border-box;
      margin-top: 1rem;
      overflow: hidden;

      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;

        .name {
          flex: 1;
          font-size: 1rem;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .detail {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: .5rem;
        background-color: #f9f9f9;
        padding: .5rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        .card {
          flex: 2;
          background-color: #ffffff;
          box-sizing: border-box;
          border: 1px solid #ddd;
          border-radius: .5rem;
          padding: 1rem;

          .label {
            font-size: 1rem;
          }

        }
      }
    }
  }
}
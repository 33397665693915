.button {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3rem;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  background-color: #FFC60C;
  color: white;
  font-weight: bold;
  margin: .5rem 0;
  font-size: calculateRem(15px);
  transition: all .3s ease;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: #eee;
    background-color: #be9304;
  }

  &:focus,
  &:focus-visible,
  &:active {
    border: 1px solid #FFC60C;
    outline: 2px solid #2d4a8a1a;
  }

  &.loading {
    .loader-wrapper {
      transform: translateY(-50%);
    }
  }

  .loader-wrapper {
    transition: all .3s ease;

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
    }
  }

  .loader {
    width: 15px;
    height: 15px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    animation: loader 1s linear infinite;
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
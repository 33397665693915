.reward-benefit-container {
  background-color: #f9f9f9;
  min-height: 100vh;
  min-height: 100dvh;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #C9B669;
    background: linear-gradient(0deg, #A4854D, #C9B669);
    border-bottom-left-radius: 10% 30%;
    border-bottom-right-radius: 10% 30%;
    margin-bottom: -4rem;
    padding: 2rem;
    padding-bottom: 4rem;

    .client-logo {
      height: 4rem;
    }

    .notification {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      background-color: #a38c51;
      border-radius: .8rem;

      .icon {
        height: 1.2rem;
        filter: brightness(0) invert(1);
      }
    }
  }

  .point-luckydraw {
    background-color: #ffffff;
    border-radius: 2rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    margin: .5rem 1rem;
    overflow: hidden;

    .top {
      position: relative;

      .title {
        padding: 1rem;
        font-weight: 600;
        text-align: center;
      }

      .arrow {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        height: 1.5rem;
      }
    }

    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: .5rem;
      background-color: #f9f9f9;
      padding: .5rem;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;

      .card {
        flex: 0 0 calc(50% - .25rem);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        box-sizing: border-box;
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-radius: 1.5rem;
        padding: .6rem;

        .icon-box {
          flex: 0 0 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          background-color: #f5f5f5;
          border-radius: .5rem;

          .icon {
            height: 20px;
            filter: brightness(0);
            opacity: .8;
          }
        }

        .amount {
          flex: 1;

          .number {
            font-size: 1rem;
            font-weight: 700;
          }

          .label {
            font-size: .7rem;
          }
        }
      }
    }
  }

  .member-card {
    position: relative;
    background-color: #ffffff;
    border-radius: 2rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    margin: .5rem 1rem;
    overflow: hidden;

    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .5rem 1rem;

      .name {
        flex: 1;
        font-size: 1rem;
        font-weight: 600;
      }

      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .4rem;
        padding: .3rem .6rem;
        background-color: #484848;
        border: 1px solid #ddd;
        border-radius: 1rem;

        .icon {
          height: 1rem;
          filter: brightness(0) invert(1);
        }

        .chevron {
          height: .6rem;
          filter: brightness(0) invert(1);
        }

        span {
          font-size: 1rem;
          font-weight: 600;
          color: white;
        }
      }
    }

    .detail {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: .5rem;
      background-color: #f9f9f9;
      padding: .5rem;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;

      .card {
        flex: 2;
        background-color: #ffffff;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 1.5rem;
        padding: 1rem;

        .label {
          font-size: 1rem;
        }

        .expense {
          font-size: 1.4rem;
          font-weight: 600;

          span {
            font-size: .8rem;
          }
        }

        .progressbar {
          height: .4rem;
          background-color: #ddd;
          border-radius: .2rem;
          margin-top: .6rem;
          overflow: hidden;

          .progress {
            width: 4px;
            height: 100%;
            background-color: #ffa500;
          }
        }
      }

      .navigation {
        background-color: #ffffff;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 1.5rem;
        padding: .5rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .card {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .2rem;
          border-radius: .9rem;
          padding: .4rem;
          width: 3rem;

          .icon {
            height: 1.5rem;
            opacity: .8;
          }

          .label {
            white-space: nowrap;
            font-size: .8rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  .features {
    position: relative;
    background-color: #ffffff;
    border-radius: 1.5rem;
    border: 1px solid #eee;
    box-sizing: border-box;
    margin: .5rem 1rem;
    overflow: hidden;

    .menu {
      display: flex;
      gap: .5rem;
      overflow: auto;
      padding: .5rem;

      .card {
        flex-shrink: 0;
        min-width: 20%;
        gap: .2rem;
        border-radius: 1rem;
        border: 1px solid #ddd;
        padding: .4rem .6rem;
        text-align: center;

        .icon {
          height: 1.6rem;
          opacity: .8;
        }

        .label {
          font-size: .7rem;
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }
  }

  .promotion {
    background-color: #ffffff;
    border-radius: 1.5rem;
    border: 1px solid #eee;
    box-sizing: border-box;
    margin: .5rem 1rem;
    overflow: hidden;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      .title {
        font-size: 1rem;
        font-weight: 600;
      }

      .see-all {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        padding: .2rem .5rem;
        background-color: #c4ae65;
        border-radius: 1rem;
        cursor: pointer;

        span {
          font-size: .9rem;
          font-weight: 500;
          color: #ffffff;
        }

        .arrow {
          display: block;
          height: .6rem;
          filter: brightness(0) invert(1);
        }
      }
    }

    .banner {
      display: block;
      width: 100%;
    }

    .swiper-horizontal>.swiper-scrollbar,
    .swiper-scrollbar.swiper-scrollbar-horizontal {
      bottom: .8rem;
      transform: scaleX(.2) scaleY(1.5);
    }
  }

  .catalogue {
    position: relative;
    background-color: #ffffff;
    border-radius: 1.5rem;
    border: 1px solid #eee;
    box-sizing: border-box;
    margin: .5rem 1rem;
    overflow: hidden;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1rem .5rem 1rem;

      .title {
        font-size: 1rem;
        font-weight: 600;
      }

      .see-all {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        padding: .2rem .5rem;
        background-color: #c4ae65;
        border-radius: 1rem;
        cursor: pointer;

        span {
          font-size: .9rem;
          font-weight: 500;
          color: #ffffff;
        }

        .arrow {
          display: block;
          height: .6rem;
          filter: brightness(0) invert(1);
        }
      }
    }

    .products {
      display: flex;
      gap: .5rem;
      overflow: auto;
      padding: .5rem;

      .card {
        flex-shrink: 0;
        width: 60%;
        gap: .2rem;
        border-radius: 1rem;
        border: 1px solid #ddd;
        overflow: hidden;

        .image {
          position: relative;

          img {
            width: 100%;
          }

          .point {
            position: absolute;
            bottom: .6rem;
            left: .4rem;
            background-color: #484848;
            color: #ffffff;
            border: 2px solid #ffffff;
            border-radius: .5rem;
            padding: .2rem .4rem;
            font-size: .8rem;
            font-weight: 600;
          }
        }

        .detail {
          padding: .4rem .8rem;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
}
.history-container {
  .content {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;

    .category {
      display: flex;
      gap: .2rem;
      overflow: auto;
      margin-bottom: 1rem;

      >div {
        background-color: white;
        padding: .4rem 1rem;
        border-radius: 2rem;
        border: 1px solid #ddd;
        // min-width: 4rem;
        white-space: nowrap;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;

        &.active {
          background-color: black;
          color: white;
        }
      }
    }

    .card {
      position: relative;
      background-color: #ffffff;
      border-radius: 1.2rem;
      border: 1px solid #ddd;
      box-sizing: border-box;
      overflow: hidden;

      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .4rem 1rem .2rem 1rem;

        .type {
          flex: 1;
          font-size: 1rem;
          font-weight: 600;
        }

        .point {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .4rem;
          padding: .3rem .6rem;
          background-color: #484848;
          border: 1px solid #ddd;
          border-radius: 1rem;

          span {
            font-size: .7rem;
            font-weight: 600;
            color: white;
          }
        }

        .icon {
          height: 1rem;
          filter: brightness(0) invert(1);
        }
      }

      .detail {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: .5rem;
        background-color: #f9f9f9;
        padding: .2rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;

        .image {
          height: 1rem;
        }

        .card {
          display: flex;
          align-items: center;
          gap: 1rem;
          flex: 2;
          background-color: #ffffff;
          box-sizing: border-box;
          border: 1px solid #eee;
          border-radius: 1rem;
          box-shadow: none;
          padding: .5rem .8rem;

          .receipt-no {
            font-size: .8rem;
            font-weight: 500;
          }

          .date {
            font-size: .8rem;
            font-weight: 500;
            margin-top: .2rem;
          }
        }
      }

    }
  }
}